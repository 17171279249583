/*------------------------------------------------------------------
[Master Stylesheet]

Project:        Studypress
Version:        1.0
Primary use:    Studypress | Education & Courses HTML Template
Author:         ThemeMascot
URL:            http://themeforest.net/user/ThemeMascot
-------------------------------------------------------------------*/
 
// Table of contents
@import "less-studypress/table-of-content.less";


// Import bootstrap variables and mixins for reference
@import (reference) "less-bootstrap/variables.less";
@import (reference) "less-bootstrap/mixins.less";


//fonts
@import "../css/font-awesome.min.css";
@import "../css/font-awesome-animation.min.css";
@import "../css/pe-icon-7-stroke.css";
/* @import "../css/elegant-icons.css"; */
/* @import "../css/stroke-gap-icons.css"; */
@import "../css/flaticon-set-carfixing.css";
@import "../css/utility-classes.css";
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,300,600,700,800);
@import url(https://fonts.googleapis.com/css?family=Raleway:400,200,300,500,600,700,800,900);
@import url(https://fonts.googleapis.com/css?family=Titillium+Web:400,200,300,600,700,900);
 
// Initialize Variables
@import "less-studypress/variables.less";
@import "less-studypress/mixins.less";
// Typography
@import "less-studypress/typography.less";

// Common Styles
@import "less-studypress/common.less";
@import "less-studypress/extra.less";
@import "less-studypress/overlay.less";

// Header
@import "less-studypress/header.less";

// Nav
@import "less-studypress/nav.less";

// Content Blocks
@import "less-studypress/topbar.less";
@import "less-studypress/inner-header-title.less";
@import "less-studypress/vertical-nav.less";
@import "less-studypress/menu-full-page.less";
@import "less-studypress/boxed-layout.less";
@import "less-studypress/form.less";
@import "less-studypress/side-push-panel.less";
@import "less-studypress/box-hover-effect.less";
@import "less-studypress/work-gallery.less";
@import "less-studypress/gallery-isotope.less";
@import "less-studypress/sliders.less";
@import "less-studypress/home.less";
@import "less-studypress/about.less";
@import "less-studypress/contact.less";
@import "less-studypress/services.less";
@import "less-studypress/shop.less";
@import "less-studypress/blog.less";

// Shortcodes
@import "less-studypress/shortcodes.less";


// Widgets
@import "less-studypress/widgets.less";


// Footer
@import "less-studypress/footer.less";